import React from "react"
import { graphql } from "gatsby"
import Civic from "../../content/pages/about/civic-action.svg";
import Learn from "../../content/pages/about/learn-reflect.svg";
import TotLogo from "../../content/pages/about/tot-logo.svg";

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.css"

class AboutPage extends React.Component {
    render() {
        const { data } = this.props
        const pageContent = data.markdownRemark;
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout title={siteTitle}>
                <SEO title={pageContent.frontmatter.title} />
                <h1 className="page-title">{pageContent.frontmatter.title}</h1>
                <div className="content-full">   
                    <section dangerouslySetInnerHTML={{ __html: pageContent.html }} /> 
                    <br/>
                    <br/>
                    <br/>
                    <div className="grid-tot">
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div><div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div><div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div><div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero rust-logo">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                        <div className="svg-hero">
                            <TotLogo/>
                        </div>
                    </div>
                </div>
                
            </Layout>
        )
    }
}

export default AboutPage

export const pageQuery = graphql`
    query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        markdownRemark(fields: {slug: {eq: "/about/"}}) {
            id
            frontmatter {
                title
                date
                description
            }
            html
        }
    }
`
